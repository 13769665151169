/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    p: "p",
    code: "code",
    strong: "strong",
    pre: "pre",
    h4: "h4",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Problems"), "\n", React.createElement(_components.h3, null, "Problem - Closed Parens"), "\n", React.createElement(_components.p, null, "For a given string composed of ", React.createElement(_components.code, null, "(){}[]"), " it is valid if each open paren ", React.createElement(_components.code, null, "[({"), " has an associated closing bracket."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// '[]'  true\n// '[}]' false\n// '[()]' true\n")), "\n", React.createElement(_components.h4, null, "Solution"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "/**\n * @param {*} v a sting composed of '[]{}()` chars\n */\nfunction isValid(v) {\n  /**\n   * time: O(n)\n   * space: O(n)\n   */\n  const stack = []\n  const sArr = v.split(\"\")\n  for (s in sArr) {\n    if (sArr[s] in key) {\n      stack.push(sArr[s])\n    } else {\n      if (stack.length < 1) {\n        return false\n      }\n      const open = stack.pop()\n      if (sArr[s] !== key[open]) {\n        return false\n      }\n    }\n  }\n  return stack.length < 1\n}\n")), "\n", React.createElement(_components.h2, null, "Remove Duplicates from Sorted Array"), "\n", React.createElement(_components.p, null, "In place implies not creating another array."), "\n", React.createElement(_components.h2, null, "References"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "leetcode.com"
  }, "LeetCode")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
